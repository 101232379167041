'use client';

import { create } from 'zustand';
type LoaderStore = {
  isLoading: boolean;
  loadingPageName: string;
  setLoading: (loading: boolean, pageName: string) => void;
};
export const useLoaderStore = create<LoaderStore>()((set, get) => ({
  isLoading: false,
  loadingPageName: '',
  setLoading: (loading, pageName) => {
    if (loading) {
      set({
        isLoading: loading,
        loadingPageName: pageName
      });
    } else {
      set({
        isLoading: loading,
        loadingPageName: ''
      });
    }
  }
}));