import { useState } from 'react';
import { Button } from '@nextui-org/button';
import { Input } from '@nextui-org/input';
import { ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/modal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import { toast } from 'sonner';
import * as Yup from 'yup';
import { UsersCacheKey } from '@/lib/types/api/cache-keys';
import { UsersMutationKeys } from '@/lib/types/api/mutation-keys';
import { revalidateCache } from '@/lib/utils/functions/cache/revalidate-cache';
import { CommonValidationMessages } from '@/lib/utils/validation-utils';
import { User } from '@/lib/services/user/user';
const schema = Yup.object().shape({
  email: Yup.string().email('El correo electrónico no es válido.').max(256, CommonValidationMessages.maxLength(256, 'el correo electrónico.')).required(CommonValidationMessages.isRequiredToEnter('el correo electrónico'))
});
export default function ForgotPassword() {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const queryClient = useQueryClient();
  const requestEmail = useMutation({
    mutationFn: async ({
      email
    }: {
      email: string;
    }) => {
      const payload = {
        email: email
      };
      return User.ForgotPassword(payload);
    },
    mutationKey: [UsersMutationKeys.RESET_PASSWORD]
  });
  return <>
      <Formik initialValues={{
      email: ''
    }}
    //@ts-ignore
    onSubmit={(values, formikHelpers) => {
      requestEmail.mutate({
        ...values
      }, {
        onError: error => {
          formikHelpers.setSubmitting(false);
          toast.error(error.message);
        },
        onSuccess: async data => {
          formikHelpers.setSubmitting(false);
          if (!data.succeeded) return;
          await revalidateCache(UsersCacheKey.CURRENT);
          setIsEmailSent(true);
          await queryClient.invalidateQueries({
            predicate: query => (query.queryKey[0] as string).startsWith(UsersCacheKey.CURRENT)
          });
          formikHelpers.setSubmitting(false);
          formikHelpers.resetForm();
          toast.success(data.message);
        }
      });
    }} validateOnMount={true} validationSchema={schema} data-sentry-element="Formik" data-sentry-source-file="forgot-password.tsx">
        {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
        values
      }) => <form onSubmit={handleSubmit}>
            <ModalContent>
              <ModalHeader className="flex flex-col gap-1">¿Olvidaste tu contraseña?</ModalHeader>
              <ModalBody>
                {isEmailSent ? <p>Por favor verifica tu correo electrónico.</p> : <Input className={'col-span-2'} errorMessage={errors.email} isDisabled={isSubmitting} isInvalid={!!errors.email} isRequired={true} label={'Correo electrónico'} maxLength={256} name={'email'} onBlur={handleBlur} onChange={handleChange} type={'email'} value={values.email} />}
              </ModalBody>
              <ModalFooter>
                {isEmailSent ? <p></p> : <Button color="primary" isDisabled={!isValid} isLoading={isSubmitting} type="submit">
                    Restablecer
                  </Button>}
              </ModalFooter>
            </ModalContent>
          </form>}
      </Formik>
    </>;
}