'use client';
import { useEffect, useState } from 'react';

interface GeolocationCoordinates {
  latitude: number;
  longitude: number;
}

interface GeolocationError {
  code: number;
  message: string;
}

const useGeolocation = () => {
  const [position, setPosition] = useState<GeolocationCoordinates | null>(null);
  const [error, setError] = useState<GeolocationError | null>(null);

  useEffect(() => {
    if (!navigator.geolocation) {
      setError({ code: 0, message: 'Geolocation is not supported by this browser.' });
      return;
    }

    const successHandler = (pos: GeolocationPosition) => {
      const { latitude, longitude } = pos.coords;
      setPosition({ latitude, longitude });
    };

    const errorHandler = (err: GeolocationPositionError) => {
      setError({ code: err.code, message: err.message });
    };

    const watcherId = navigator.geolocation.watchPosition(successHandler as PositionCallback, errorHandler as PositionErrorCallback, {
      enableHighAccuracy: true,
    });

    return () => {
      navigator.geolocation.clearWatch(watcherId);
    };
  }, []);

  return { error, position };
};

export default useGeolocation;
