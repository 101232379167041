'use client';
import { useEffect, useState } from 'react';

const usePermission = (name: PermissionDescriptor['name']) => {
  const [status, setStatus] = useState<PermissionState>('prompt');

  useEffect(() => {
    let isMounted = true;

    if (!('permissions' in navigator)) {
      console.error('Permissions API is not supported by this browser.');
      return;
    }

    const queryPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({ name });
        if (isMounted) setStatus(permissionStatus.state);

        const handleChange = () => {
          if (isMounted) setStatus(permissionStatus.state);
        };

        permissionStatus.addEventListener('change', handleChange);

        return () => {
          permissionStatus.removeEventListener('change', handleChange);
        };
      } catch (error) {
        console.error('Error querying permission:', error);
      }
    };

    queryPermission();

    return () => {
      isMounted = false;
    };
  }, [name]);

  return status;
};

export default usePermission;
